import { last } from 'lodash';
import PropTypes from 'prop-types';
// material
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Box, Link, Breadcrumbs } from '@mui/material';

// ----------------------------------------------------------------------

const Separator = (
  <Box component="span" sx={{ margin: 0 }}>
    -
  </Box>
);

LinkItem.propTypes = {
  link: PropTypes.object,
};

function LinkItem({ link, color }) {
  const { href, name, icon } = link;
  return (
    <Link
      to={href}
      key={name}
      variant="body2"
      component={RouterLink}
      sx={{
        lineHeight: 2,
        display: 'flex',
        alignItems: 'center',
        color: color,
        fontWeight: 600,
        '& > div': { display: 'inherit' },
      }}
    >
      {icon && (
        <Box
          sx={{
            mr: 1,
            '& svg': { width: 20, height: 20 },
          }}
        >
          {icon}
        </Box>
      )}
      {name}
    </Link>
  );
}

MBreadcrumbs.propTypes = {
  links: PropTypes.array.isRequired,
  activeLast: PropTypes.bool,
};

const handleReload = () => {
  // eslint-disable-next-line no-restricted-globals
  location.reload();
};

export default function MBreadcrumbs({
  links,
  activeLast = false,
  crumbsColor,
  ...other
}) {
  const currentLink = last(links)?.name;

  const listDefault = links.map((link) => (
    <LinkItem key={link.name} link={link} />
  ));
  const listActiveLast = links.map((link) => (
    <div key={link.name}>
      {link.name !== currentLink ? (
        <LinkItem link={link} color={crumbsColor ? crumbsColor : '#353350'} />
      ) : (
        <Typography
          variant="body2"
          sx={{
            maxWidth: 260,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: crumbsColor ? crumbsColor : '#7635dc',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
          }}
          onClick={handleReload}
        >
          {currentLink}
        </Typography>
      )}
    </div>
  ));

  return (
    <Breadcrumbs separator={Separator} {...other}>
      {activeLast ? listDefault : listActiveLast}
    </Breadcrumbs>
  );
}
