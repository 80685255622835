import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  isLoadinggeneralNumbers: false,
  isLoadingsalesPie: false,
  isLoadingsalesChart: false,
  generalNumbers: {
    productsCost: 0,
    productsCount: 0,
    boughtPercent: 0,
    totalRevenue: 0,
  },
  salesPie: {
    giro: [
      {
        product: '',
        giro: 0,
        revenue: 0,
        sales_count: 0,
        cancel_count: 0,
        cancel_percent: 0,
        nm_id: '',
      },
    ],
  },
  salesChart: {
    sales: [],
    orders: [],
    giro: [],
    revenue: [],
    date: [],
  },
};

const slice = createSlice({
  name: 'analytic',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.isLoadinggeneralNumbers = true;
      state.isLoadingsalesPie = true;
      state.isLoadingsalesChart = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isLoadinggeneralNumbers = false;
      state.isLoadingsalesPie = false;
      state.isLoadingsalesChart = false;
      state.error = action.payload;
    },

    // GET GENERAL NUMBERS
    getGeneralNumbersSuccess(state, action) {
      state.isLoadinggeneralNumbers = false;
      state.generalNumbers = {
        productsCost: action.payload['products_cost'],
        productsCount: action.payload['products_count'],
        boughtPercent: action.payload['bought_percent'],
        totalRevenue: action.payload['total_revenue'],
      };
    },

    // GET SALES CHART
    getSalesChartSuccess(state, action) {
      state.isLoadingsalesChart = false;
      state.salesChart = action.payload;
    },

    // GET SALES PIE
    getSalesPieSuccess(state, action) {
      state.isLoadingsalesPie = false;
      state.salesPie = {
        giro: action.payload,
      };
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getGeneralNumbers(filter_data) {
  const queryString = Object.keys(filter_data)
    .map((key) => key + '=' + filter_data[key])
    .join('&');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        '/api/store/analytics/main/?' + queryString,
      );
      dispatch(slice.actions.getGeneralNumbersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSalesChart(filter_data) {
  const queryString = Object.keys(filter_data)
    .map((key) => key + '=' + filter_data[key])
    .join('&');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        '/api/store/analytics/chart/list/?' + queryString,
      );
      dispatch(slice.actions.getSalesChartSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSalesPie(filter_data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/store/analytics/sales/', {
        params: filter_data,
      });

      dispatch(slice.actions.getSalesPieSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
