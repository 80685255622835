import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { MenuItem, Stack, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  authReduxSelector,
  authRefreshStoreDataThunk,
  authUpdateStoreThunk,
} from 'src/redux/slices/authentication';
import { DATA_IDS } from 'src/utils/cypress';
import { setDataTestId } from 'src/utils/setDataTestId';
import toast from 'src/utils/snackBarUtils';

export default function AccountBrands({
  checkTaskStatus,
  isInPopover = false,
}) {
  const { store } = useSelector((state) => authReduxSelector(state));
  const { brands } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleChangeBrand = (event) => {
    dispatch(
      authUpdateStoreThunk({
        settings: {
          brand_id: event.target.value,
        },
      }),
    );
  };

  const handleRefreshData = async (event) => {
    try {
      const response = await dispatch(authRefreshStoreDataThunk()).unwrap();

      const variant = response.data.state === 'SUCCESS' ? 'success' : 'error';

      toast?.[variant](response.data.result, { persist: true });
    } catch (error) {
      toast.error(
        error.error
          ? String(error.error)
          : String('Ошибка соединения с сервером'),
        { persist: true },
      );
      return;
    }

    checkTaskStatus();
  };

  const { myShop, navbar } = DATA_IDS.dashboard;

  return (
    <Stack
      direction={isInPopover ? 'column' : 'row'}
      spacing={isInPopover ? 1.5 : 1}
    >
      <LoadingButton
        fullWidth={isInPopover}
        sx={{
          pr: store.is_pulling_data ? 5 : 2,
          my: 'auto',
          textTransform: 'none',
          textWrap: 'wrap',
          borderRadius: '25px',
        }}
        style={{ textTransform: 'none' }}
        onClick={handleRefreshData}
        loadingPosition="end"
        startIcon={<Icon icon="iconoir:refresh" />}
        variant="contained"
        loading={store.is_pulling_data}
        {...setDataTestId({ name: navbar.update_data_btn })}
      >
        {store.is_pulling_data ? 'Данные обновляются' : 'Обновить данные'}
      </LoadingButton>

      {brands.length > 0 && (
        <TextField
          select
          value={brands.length > 0 ? store?.settings?.brand_id : 'all'}
          onChange={handleChangeBrand}
          size="small"
          fullWidth={isInPopover}
          inputProps={{
            style: {
              height: '35px',
            },
          }}
          {...setDataTestId({ name: myShop.brands_option })}
        >
          <MenuItem key={'all'} value={'all'}>
            Все бренды
          </MenuItem>
          {brands.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      )}
    </Stack>
  );
}
